table.cart-table {
  thead {
    th, td{
      padding-bottom: 1em;
    }
  }
  tbody {
    td{
      // padding-top: 1.5em;
      padding-bottom: 1.5em;
    }
  }
}
table.total-summary {
  .total {
    font-size: 1.25em;
    font-weight: 600;
  }
}
.cart-remove {
  color: rgba(#ff0000, .6);
  cursor: pointer;
  &:hover {
    color: #ff0000;
  }
}
.cart-qty-control {
  display: flex;
  align-items: center;

  .plus,
  .minus {
    cursor: pointer;
    color: rgba(#666, .8);
    &:hover {
      color: #000;
    }
    &.disabled {
      cursor: not-allowed;
      opacity: .5;
    }
  }
}
table.order-table {
  thead {
    th, td{
      padding-bottom: 1em;
    }
  }
  tbody {
    td{
      padding-top: 1em;
      padding-bottom: 1em;
    }
  }
}
