.footer {
  margin-top: 2em;
  padding: 70px 0 10px !important;
}

.footer-logo {
  height: 10em;
  // width: 80%;
  margin-bottom: 1em;
}

.mouse-icon {
  background-color: $maincolor;
  border: 1px solid $maincolor;
}
.footer-heading {
  color: $maincolor;
  font-size: 1.5em;
  margin-bottom: .75em;
}
// .footer-menu {
//   list-style-type: none;
//   padding-left: 0;
// }
// .rtl .footer-menu {
//   padding-right: 0;
// }
.footer-icon {
  color: $maincolor;
  text-transform: uppercase;
  font-weight: bolder !important;
  font-size: 1.5rem !important;
}

.social-icon {
  cursor: pointer;
}
