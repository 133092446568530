.navbar-wrapper {
  position: relative;
}

.navbar {
  // background-color: #000;
  background-color: $maincolor;
  z-index: 10;
}

.navbar-brand {
  color: #000;

  &:hover,
  &:focus {
    color: #ddd;
    // opacity: 0.7;
  }
}

.nav-item {
  margin-left: .75em;
  margin-right: .75em;

  .nav-login,
  .nav-link {
    font-size: 1em;
    letter-spacing: 2px;
    text-transform: uppercase;
    font-weight: 700;
    color: #000;

    &:hover,
    &:focus,
    &.active {
      color: #fff;
      // font-weight: 500;
    }
  }

  .nav-login {
    padding-top: .5rem;
  }
}

.nav-toggler {
  // color: white;

  &:focus,
  &:active {
    border: none;
    outline: none;
    box-shadow: none;
  }
}

.nav-fade {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 1;
}

.nav-login-wrapper {
  cursor: pointer;
}

// .nav-login {
//   color: #000;
//   // color: rgba(255, 255, 255, .55);
//   // background-color: $maincolor;
//   border: none;
//   cursor: pointer;

//   &:focus {
//     // background: $maincolor;
//     // background-color: $maincolor !important;
//     box-shadow: none !important;
//     border: none;
//     cursor: pointer;
//   }

//   &:hover {
//     // background-color: $maincolor;
//     color: rgba(255, 255, 255, .75);
//     border: none;
//   }
// }

.dropdown-logout-wrapper {
  background-color: white;

  &:hover {
    background-color: white !important;
    background: white !important;
    opacity: 1 !important;
  }
}

.dropdown-logout {
  color: black;
  background-color: white;

  &:focus {
    background-color: white;
    color: black;
    outline: none;
  }
}