.carousel-item, .carousel-loader {
  height: 50vh;
}
.carousel-loader {
  background-color: rgba(0, 0, 0, .8);
}
@media (min-width:991px) {
  .carousel-item, .carousel-loader {
    height: calc(100vh - 80px);
  }
}

.carousel-caption h5 {
  color: #fff;
  text-transform: uppercase;
}

.carousel-item {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: rgba(0, 0, 0, .8);
}

// .carousel-item {
//   display: flex;
//   flex-flow: column;
//   justify-content: center;
//   align-items: center;
//   width: 100%;
//   height: 700px;
//   max-height: 90vh;
//   background-position: center;
//   background-size: cover;
//   float: none;
//   background-repeat: no-repeat;
// }

// .carousel-item-sub {
//   width: 100%;
//   height: 700px;
//   max-height: 90vh;
//   display: flex;
//   flex-flow: column;
//   justify-content: center;
//   align-items: center;
//   background-position: center;
//   background-size: cover;
//   background-repeat: no-repeat;
// }

// .carousel-heading {
//   text-transform: uppercase;
//   font-weight: bold;
//   font-size: 7rem;
//   color: white;
//   font-family: "Amatic SC", cursive;
//   cursor: context-menu;
// }

@media (max-width: 678px) {
  // .carousel-item {
  //   height: 500px;
  //   max-height: 90vh;
  // }

  // .carousel-item-sub {
  //   height: 500px;
  //   max-height: 90vh;
  // }

  // .carousel-heading {
  //   font-size: 4rem;
  // }
}

// .carousel-subtitle {
//   text-transform: uppercase;
//   font-size: 0.8rem;
//   font-weight: bold;
//   color: white;
//   letter-spacing: 4px;
//   cursor: context-menu;
// }

.submit-recipe-btn:focus {
  box-shadow: none !important;
  border-color: $maincolor !important;
  outline-color: $maincolor !important;
  background-color: transparent !important;
  color: $maincolor !important;
}

.category {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
}

.category-wrap-2 {
  // background-color: rgba(243, 214, 0, .1);
  background: rgba(0, 0, 0, 0.39);
  overflow: hidden;
}

.category-wrap-2 h2 {
  color: white !important;
  font-weight: bold;
}

.category-wrap-2 p {
  color: white !important;
  font-weight: 300;
}

// .category-wrap-2 a {
//   color: $maincolor !important;
//   background-color: white !important;
//   border: 1px solid white !important;
// }

// .category-wrap-2 a:hover {
//   color: white !important;
//   border: 1px solid $maincolor !important;
//   background-color: $maincolor !important;
// }

.category-wrap-2 a:focus {
  box-shadow: none !important;
}

.categories-title {
  background-color: $maincolor !important;
}

.categories-title p {
  cursor: pointer !important;
  margin: 3px 0;
}

.featured-bottom {
  color: #fff;
  width: 100%;
  background: $maincolor;
  width: 40px;
  height: 40px;
  margin: 0 auto;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  cursor: pointer;
}

.img-prod img {
  height: 220px;
  width: 100%;
  object-position: center !important;
  object-fit: contain !important;
}

.bottom-area a {
  background: $maincolor !important;
}

.price {
  color: $maincolor !important;
}

.ad-home {
  height: 600px;
}

.ftco-category {
  .category-wrap {
    background-size: contain;
    background-color: rgba(243, 214, 0, .1);

    .categories-title {
      background-color: #000 !important;
    }
  }
}

.categories-or-text {
  color: $maincolor;
  font-size: 1.5rem;
  font-weight: bold;
}

.social-feed {
  width: 100%;
  height: 84%;
  min-height: 20em;
  background-color: #eee;
  margin: 0 auto;
}
.section-title {
  margin-bottom: 1em;
}