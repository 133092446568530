.hero-wrap {
  width: 100%;
  position: relative;
  transition: 0.3s all ease-out;
  // background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.hero-wrap .overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  opacity: 0.6;
  width: 50%;
  background: $maincolor;
}

.hero-wrap .slider-text {
  color: #fff;
  position: relative;
}

.hero-wrap .slider-text .breadcrumbs {
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 3px;
  margin-bottom: 0;
  z-index: 99;
  font-weight: 300;
}

.hero-wrap .slider-text .breadcrumbs span {
  color: white;
}

.hero-wrap .slider-text .breadcrumbs span a {
  color: #fff;
}

.hero-wrap .slider-text .bread {
  font-weight: 800;
  color: #fff;
  font-size: 30px;
  font-family: "Poppins", Arial, sans-serif;
  letter-spacing: 3px;
  text-transform: uppercase;
}

.hero-wrap .slider-text .btn-primary {
  border: 1px solid rgba(255, 255, 255, 0.4);
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  border-radius: 30px;
}

.hero-wrap .slider-text .btn-primary:hover,
.hero-wrap .slider-text .btn-primary:focus {
  background: #fff !important;
  color: #000000;
}

.hero-wrap.hero-bread {
  padding: 10em 0;
}

.hero-wrap.hero-bread-small {
  padding: 5em 0;
}