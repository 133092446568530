.breadcrumb-item a {
  color: $maincolor;
}

.rtl {
  .breadcrumb-item + .breadcrumb-item {
    padding-left: 0;

    &::before {
      float: right;
      padding-left: $breadcrumb-item-padding-x;
      padding-right: .5em;
    }
  }
}