section {
  padding-top: 3em;
  padding-bottom: 3em;
}
.bg-light {
  background: #f7f6f2 !important;
}

.bg-primary {
  background: $maincolor;
}

.bg-secondary {
  background: #ffe6eb !important;
}

.bg-color-1 {
  background: #e4b2d6;
}

.bg-color-2 {
  background: #dcc698;
}

.bg-color-3 {
  background: #a2d1e1;
}

.bg-color-4 {
  background: #dcd691;
}


.pointer {
  cursor: pointer !important;
}

.btn.disabled {
  cursor: not-allowed !important;
}

.items-empty {
  width: 100%;
  height: 25vh;
  display: flex;
  align-items: center;
  justify-content: center;
}