.form-control:is(:required)~label,
.form-select:is(:required)~label {
  &:after {
    content: " *";
    color: $danger;
  }
}

.required-notice {
  color: $danger;
}

.rtl {
  .form-floating>.form-control {
    padding-right: 1em;
  }
    .form-floating>label {
    right: 0;
    padding-right: .75rem;
    left: unset;
  }

  .form-floating>.form-select {
    background-position: left 0.75rem center;
    padding-right: 0.75em;
  }
}